@import '../../global/styles/lib';

$text-max-width: 55%;
$sep-max-width: 65%;

.most-video-banner {
    @include full-bleed;
    background-color: black;
    overflow: hidden;

    .wrapper {
        height: auto;
        margin: auto;
        position: relative;
        max-width: $row-max-width;

        &::before,
        &::after {
            content: "";
            @include absolute-full;
            z-index: 1;
        } // &::before, &::after

        &::before {
            background-image: $video-gradient;
        } // &::before
        
        video {
            @include absolute-full;
            object-position: bottom center;
            object-fit: cover;
            opacity: 0;
            transition: $video-banner-transition;

            &.open {
                opacity: 1;
            } // &.open

            @include breakpoint($video-break down) {
                object-fit: contain;
            } // $video-break down
        } // video

        .content {
            position: relative;
            padding: 2rem 0;

            h1 {
                color: $white !important;
                margin: 0 !important;
                font-size: 2rem !important;
                z-index: 2;
            } // h1

            .sep {
                background: rgba($white, 0.5);
                margin: 1rem 0;
                height: 1px;
                max-width: 100%;
            } // .sep

            .subheading {
                margin-bottom: 2rem;
                color: rgba($white, 0.5);
                font-size: 1rem;
            } // .subheading

            .button-group {
                display: flex;
                flex-direction: column;
                margin-bottom: 4rem;

                .button {
                    text-transform: uppercase;
                    width: fit-content;
                    width: -moz-fit-content; // dang firefox
                    font-size: 0.8rem;
                    margin-top: 1rem;
                    &:first-child {
                        margin-bottom: 0;
                    } // &:first-child

                } // .button
            } // .button-group

            h1, .subheading, .button-group {
                position: relative;
                z-index: 2;
                max-width: 100%;
                margin-left: 1rem !important;
            } // h1, .subheading, .button-group

            @include breakpoint(medium up) {
                padding: 4rem 0;
                .sep {
                    max-width: $sep-max-width;
                    margin: 2rem 0;
                } // .sep

                h1 {
                    font-size: 2.5rem !important;
                } // h1

                .subheading {
                    font-size: 1.5rem;
                } // .subheading

                .button-group {
                    margin-bottom: 0;
                    flex-direction: row;
                    .button {
                        font-size: 1rem;
                        width: auto;
                        margin-left: 1rem;
                        margin-bottom: 0;
                        &:first-child {
                            margin-left: 0;
                        } // &:first-child
                    } // .button

                } // .button-group
                
                h1, .subheading, .button-group {
                    max-width: $text-max-width;
                    margin-left: 2rem !important;
                } // h1, .subheading, .button-group
            } // medium up

            @include breakpoint(large up) {
                padding: 6rem 0;
                h1 {
                    font-size: 4rem !important;
                }
                .subheading {
                    font-size: 1.5rem;
                } // .subheading
            }

        } // .content
    } // .wrapper

} // .most-video-banner