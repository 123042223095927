/**
 * Imports libraries of settings, mixins, functions, etc. that should be available
 * to all stylesheets. MUST NOT OUTPUT STYLES.
 *
 * @author    <corey@chatmandesign.com>
 * @since     3.0.0
 */
/**
 * Breakpoint range variables for the lock mixin.
 * Convert the breakpoints map into range variables like we had in ZF5. The
 * magic number at the end is the difference between the breakpoint we define in
 * $breakpoints and the actual max-width value spit out by the breakpoints()
 * mixin when we give it an "only" argument (to avoid overlap. See the ZF5 media
 *  query range variables for reference.)
 *
 * $small-range is a special snowflake. The whole purpose of the lock mixin is
 * to let you set a property to a fixed value up to a certain breakpoint, then
 * have that value increase in a linear fashion until you hit another
 * breakpoint, and then keep the value fixed again for the rest of the way. The
 * way the breakpoints are defined, the small range would be 0px - 640px. But
 * we're not planning on supporting devices smaller than 320px, so it's far more
 * useful for the small range to be 320px to 640px. That way, the $min-value you
 * pass to the lock mixin is anchored to the smallest device width we support,
 * and the $max-value is anchored to the breakpoint between small and medium
 * ranges.
 *
 * @author   <dustin@chatmandesign.com>
 */
/**
 * Sass Mixins
 *
 * @package   seedyjoints
 * @since     1.0
 */
/**
 * Extend element's background to left and right edges of viewport.
 *
 * @param string $position  Any valid CSS position value, or NULL/FALSE to not set
 *                          position; but note the element must create a positioning
 *                          context for the bleed effect to work. Default: relative.
 * @param string $direction Direction the bleed will extend in -- left, right, both.
 *                          Deafult: both.
 */
/**
 * Lock (Fluid Type Mixin -- CD Remix)
 * Created 10/11/16 @ 02:19:30 PM
 *
 * Original from here:
 * http://madebymike.com.au/writing/precise-control-responsive-typography/
 *
 * This is a modified version of the above mixin that takes a single SCSS list
 * instead of two variables for min and max VW. This makes it easier to use with
 * the existing Foundation range variables in _settings.scss. You can of course
 * still pass a plain list e.g. "(20rem, 40rem)" as the argument for the range
 * parameter if you need a custom range.
 *
 * @param      $properties A space separated list of CSS properties
 * @param      $vw-range A SCSS list defining a viewport width range like
 *             "(20em, 40em)".
 * @param      $min-value A minimum property value in px/em/rem
 * @param      $max-value A maximum property value in px/em/rem
 * @author     Chatman Design / Dustin Paluch <dustin@chatmandesign.com>
 */
/**
 * List items inline, separated by pipes by default.
 *
 * @since    3.0.0
 * @author   <corey@chatmandesign.com>
 */
/**
 * Transition property with vendor prefixes (DEPRECATED)
 *
 * DEPRECATED now that we use autoprefixer. Just use the standard CSS property.
 *
 * I totally rewrote this to be cleaner and easier to use. You'll need to be using
 * Sass 3.2+ for these to work. Thanks to @anthonyshort for the inspiration on these.
 *
 * USAGE: @include transition(<property> <time> <easing>);
 */
/**
 * CSS3 Gradients with vendor prefixes
 *
 * Be careful with these since they can really slow down your CSS. Don't overdo it.
 */
/* @include css-gradient(#dfdfdf,#f8f8f8); */
/**
 * Foundation for Sites by ZURB
 * Version 6.4.0
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
.most-video-banner {
  width: 100vw;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  max-width: 160rem;
  background-color: black;
  overflow: hidden;
}
.most-video-banner .wrapper {
  height: auto;
  margin: auto;
  position: relative;
  max-width: 75rem;
}
.most-video-banner .wrapper::before, .most-video-banner .wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.most-video-banner .wrapper::before {
  background-image: linear-gradient(to right, black, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0) 90%, black);
}
.most-video-banner .wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: bottom center;
  object-fit: cover;
  opacity: 0;
  transition: 1s ease-in-out;
}
.most-video-banner .wrapper video.open {
  opacity: 1;
}
@media screen and (max-width: 41.625em) {
  .most-video-banner .wrapper video {
    object-fit: contain;
  }
}
.most-video-banner .wrapper .content {
  position: relative;
  padding: 2rem 0;
}
.most-video-banner .wrapper .content h1 {
  color: #fefefe !important;
  margin: 0 !important;
  font-size: 2rem !important;
  z-index: 2;
}
.most-video-banner .wrapper .content .sep {
  background: rgba(254, 254, 254, 0.5);
  margin: 1rem 0;
  height: 1px;
  max-width: 100%;
}
.most-video-banner .wrapper .content .subheading {
  margin-bottom: 2rem;
  color: rgba(254, 254, 254, 0.5);
  font-size: 1rem;
}
.most-video-banner .wrapper .content .button-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
}
.most-video-banner .wrapper .content .button-group .button {
  text-transform: uppercase;
  width: fit-content;
  width: -moz-fit-content;
  font-size: 0.8rem;
  margin-top: 1rem;
}
.most-video-banner .wrapper .content .button-group .button:first-child {
  margin-bottom: 0;
}
.most-video-banner .wrapper .content h1, .most-video-banner .wrapper .content .subheading, .most-video-banner .wrapper .content .button-group {
  position: relative;
  z-index: 2;
  max-width: 100%;
  margin-left: 1rem !important;
}
@media screen and (min-width: 40em) {
  .most-video-banner .wrapper .content {
    padding: 4rem 0;
  }
  .most-video-banner .wrapper .content .sep {
    max-width: 65%;
    margin: 2rem 0;
  }
  .most-video-banner .wrapper .content h1 {
    font-size: 2.5rem !important;
  }
  .most-video-banner .wrapper .content .subheading {
    font-size: 1.5rem;
  }
  .most-video-banner .wrapper .content .button-group {
    margin-bottom: 0;
    flex-direction: row;
  }
  .most-video-banner .wrapper .content .button-group .button {
    font-size: 1rem;
    width: auto;
    margin-left: 1rem;
    margin-bottom: 0;
  }
  .most-video-banner .wrapper .content .button-group .button:first-child {
    margin-left: 0;
  }
  .most-video-banner .wrapper .content h1, .most-video-banner .wrapper .content .subheading, .most-video-banner .wrapper .content .button-group {
    max-width: 55%;
    margin-left: 2rem !important;
  }
}
@media screen and (min-width: 64em) {
  .most-video-banner .wrapper .content {
    padding: 6rem 0;
  }
  .most-video-banner .wrapper .content h1 {
    font-size: 4rem !important;
  }
  .most-video-banner .wrapper .content .subheading {
    font-size: 1.5rem;
  }
}